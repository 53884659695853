import { createRouter, createWebHistory } from 'vue-router';

import Home from '../views/index.vue';
import Home2 from '../views/index_viejo.vue';
import HomeDokma from '../views/index_data_dokma.vue';

import store from '../store';

// Vue.use(VueRouter);
// import { createApp } from 'vue';
// const app = createApp();
// app.use(createRouter);

const routes = [
  //dashboard
  { path: '/', name: 'Home2', component: HomeDokma },
  { path: '/H2', name: 'HomeIV', component: Home2 },


  {
    path: '/Page-content',
    name: 'Page content',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/MenusSelection/ContentPage.vue'),
  },
  {
    path: '/SetupStore',
    name: 'Setup Store',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/MenusSelection/SetupStore.vue'),
  },
  {
    path: '/set-up-shops',
    name: 'Setup shop',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/MenusSelection/Setupshop.vue'),
  },
  {
    path: '/SuperAdmin',
    name: 'SuperAdmin',
    component: () => import(/* webpackChunkName: "SuperAdmin" */ '../views/superAdmin/index.vue'),
  },
  {
    path: '/Methods',
    name: 'Methods',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/MenusSelection/Methods.vue'),
  },

  {
    path: '/Apps',
    name: 'Apps',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/Apps_Gestor/Apps.vue'),
  },

  {
    path: '/NewApps',
    name: 'NewApps',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/Apps_Gestor/NewApps.vue'),
  },

  {
    path: '/UpdateApp/:id',
    name: 'UpdateApp',
    component: () => import(/* webpackChunkName: "pages-blank-page" */ '../views/admin/Sales/Apps_Gestor/UpdateApp.vue'),
  },

  {
    path: '/configuration',
    name: 'configuration',
    component: () => import(/* webpackChunkName: "pages-blank-page" */ '../views/admin/Sales/Configuration/configuration.vue'),
  },

  {
    path: '/Theme',
    name: 'Theme',
    component: () => import(/* webpackChunkName: "pages-blank-page" */ '../views/admin/Sales/Configuration/theme.vue'),
  },

  {
    path: '/Ads',
    name: 'Ads',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/Ads/Ads.vue'),
  },

  {
    path: '/NewAds',
    name: 'NewAds',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/Ads/NewAds.vue'),
  },

  {
    path: '/UpdateAds/:id',
    name: 'UpdateAds',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/Ads/UpdateAds.vue'),
  },

  {
    path: '/Brands',
    name: 'Brands',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/Brands/Brands.vue'),
  },

  {
    path: '/NewBrands',
    name: 'NewBrands',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/Brands/NewBrands.vue'),
  },

  {
    path: '/UpdateBrands/:id',
    name: 'UpdateBrands',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/Brands/UpdateBrands.vue'),
  },

  {
    path: '/Features',
    name: 'Features',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/Features/Features.vue'),
  },

  {
    path: '/NewFeatures',
    name: 'NewFeatures',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/Features/NewFeatures.vue'),
  },

  {
    path: '/UpdateFeatures/:id',
    name: 'UpdateFeatures',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/Features/UpdateFeatures.vue'),
  },

  {
    path: '/UpdateInformation',
    name: 'UpdateInformation',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/Information/UpdateInformation.vue'),
  },

  {
    path: '/Notifications',
    name: 'Notifications',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/Notifications/Notifications.vue'),
  },

  {
    path: '/Payment',
    name: 'PaymentMethods',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/PaymentMethods/Payment.vue'),
  },

  {
    path: '/ELP',
    name: 'Elp-Dokma',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/PaymentMethods/modules/ELP/Elp.vue'),
  },
  {
    path: '/Bepay',
    name: 'Bepay-Contento',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/PaymentMethods/modules/BEPAY/Bepay.vue'),
  },
  {
    path: '/Sistecredito',
    name: 'Sistecredito-Contento',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/PaymentMethods/modules/SISTECREDITO/Sistecredito.vue'),
  },
  {
    path: '/setKeys/Sistecredito',
    name: 'SetKeys-Sistecredito-Contento',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/PaymentMethods/modules/SISTECREDITO/SetKeys.vue'),
  },
  {
    path: '/Bepay/Signature',
    name: 'Bepay-Signature',
    component: () => import(/* webpackChunkName: "pages-blank-page" */ '../views/admin/Sales/PaymentMethods/modules/BEPAY/signature.vue'),
  },

  {
    path: '/Epayco',
    name: 'Epayco',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/PaymentMethods/modules/EPAYCO/Epayco.vue'),
  },

  {
    path: '/CredentialsEpayco',
    name: 'CredentialsEpayco',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/PaymentMethods/modules/EPAYCO/CredentialsEpayco.vue'),
  },

  {
    path: '/NewPayment',
    name: 'NewPayment',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/PaymentMethods/NewPayment.vue'),
  },

  {
    path: '/UpdatePayment/:id',
    name: 'UpdatePayment',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/PaymentMethods/UpdatePayment.vue'),
  },

  {
    path: '/Shipping',
    name: 'ShippingMethods',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/ShippingMethods/Shipping.vue'),
  },

  {
    path: '/AgileDispatch',
    name: 'AgileDispatch',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/ShippingMethods/modules/despachoAgil/despacho.vue'),
  },

  {
    path: '/agiledispatch/summary',
    name: 'AgileDispatchSummary',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/ShippingMethods/modules/despachoAgil/modules/summary.vue'),
  },

  {
    path: '/Shippers',
    name: 'Shippers',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/ShippingMethods/modules/shippers/Shippers.vue'),
  },

  {
    path: '/NewShipping',
    name: 'NewShipping',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/ShippingMethods/NewShipping.vue'),
  },

  {
    path: '/UpdateShipping/:id',
    name: 'UpdateShipping',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/ShippingMethods/UpdateShipping.vue'),
  },

  {
    path: '/Shops/:id',
    name: 'Shops Edit',
    component: () => import(/* webpackChunkName: "pages-blank-page" */ '../views/admin/editShops.vue'),
  },
  {
    path: '/reports',
    name: 'Reports',
    component: () => import(/* webpackChunkName: "pages-blank-page" */ '../views/admin/reports/reportsSales.vue'),
  },
  {
    path: '/Categories',
    name: 'Categories',
    component: () => import(/* webpackChunkName: "pages-blank-page" */ '../views/admin/Sales/Categories/Categories.vue'),
  },
  {
    path: '/NewCategory',
    name: 'NewCategory',
    component: () => import(/* webpackChunkName: "pages-blank-page" */ '../views/admin/Sales/Categories/NewCategory.vue'),
  },
  {
    path: '/UpdateCategory/:id',
    name: 'UpdateCategory',
    component: () => import(/* webpackChunkName: "pages-blank-page" */ '../views/admin/Sales/Categories/UpdateCategory.vue'),
  },
  {
    path: '/NewSubcategory/:id',
    name: 'NewSubcategory',
    component: () => import(/* webpackChunkName: "pages-blank-page" */ '../views/admin/Sales/Categories/NewSubcategory.vue'),
  },
  {
    path: '/UpdateSubcategory/:id',
    name: 'UpdateSubcategory',
    component: () => import(/* webpackChunkName: "pages-blank-page" */ '../views/admin/Sales/Categories/UpdateSubcategory.vue'),
  },

  //List_users
  {
    path: '/create_users',
    name: 'CreateUsers',
    component: () => import(/* webpackChunkName: "pages-blank-page" */ '../views/admin/Users/createUser.vue'),
  },

  {
    path: '/users_list',
    name: 'UsersList',
    component: () => import(/* webpackChunkName: "pages-blank-page" */ '../views/admin/Users/UsersList.vue'),
  },

  {
    path: '/user/:id',
    name: 'Persona',
    component: () => import(/* webpackChunkName: "pages-blank-page" */ '../views/admin/Users/userProfile.vue'),
  },

  {
    path: '/Profile',
    name: 'Mi account',
    component: () => import(/* webpackChunkName: "pages-blank-page" */ '../views/admin/MyProfile.vue'),
  },

  {
    path: '/testaxios',
    name: 'axios-vue',
    component: () => import(/* webpackChunkName: "pages-blank-page" */ '../views/testing/axios.vue'),
  },

  {
    path: '/index2',
    name: 'index2',
    component: () => import(/* webpackChunkName: "index2" */ '../views/index2.vue'),
  },

  {
    path: '/testemit',
    name: 'Testemit',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/testEmit.vue'),
  },

  {
    path: '/Shops',
    name: 'Shops',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/Shops/Shops.vue'),
  },

  {
    path: '/Menus',
    name: 'Menus',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/Menus/_main.vue'),
  },
  {
    path: '/Menu',
    name: 'Menu',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/Menus/_menus.vue'),
  },
  {
    path: '/MenusRoles',
    name: 'Menus by profile',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/Menus/_menusRoles.vue'),
  },

  {
    path: '/Orders',
    name: 'Orders',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/Orders/Orders.vue'),
  },
  {
    path: '/Fulfillments',
    name: 'Fulfillments',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/Orders/DispatchOrders.vue'),
  },

  {
    path: '/NewGuideShipment/:id',
    name: 'NewGuideShipment',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/Orders/NewGuideShipment.vue'),
  },
  {
    path: '/GuideShipment/:id',
    name: 'GuideShipment',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/Orders/UpdateGuideShipment.vue'),
  },

  {
    path: '/Organizational',
    name: 'Organizational',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/Organizational/Organizational.vue'),
  },

  {
    path: '/NewOrganizational',
    name: 'NewOrganizational',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/Organizational/NewOrganizational.vue'),
  },

  {
    path: '/NewBrief',
    name: 'NewBrief',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/Brief/NewBrief.vue'),
  },

  {
    path: '/ListBrief',
    name: 'ListBrief',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/Brief/ListBrief.vue'),
  },

  {
    path: '/EditBrief/:id',
    name: 'EditBrief',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/Brief/EditBrief.vue'),
  },

  {
    path: '/ListFaqs',
    name: 'ListFaqs',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/FAQs/ListFaqs.vue'),
  },

  {
    path: '/NewFaq',
    name: 'NewFaq',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/FAQs/NewFaq.vue'),
  },

  {
    path: '/UpdateFaq/:id',
    name: 'UpdateFaq',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/FAQs/UpdateFaq.vue'),
  },

  {
    path: '/Reviews',
    name: 'Reviews',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/Reviews/Reviews.vue'),
  },

  {
    path: '/NewReview',
    name: 'NewReview',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/Reviews/NewReview.vue'),
  },

  {
    path: '/UpdateReview/:id',
    name: 'UpdateReview',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/Reviews/UpdateReviews.vue'),
  },

  {
    path: '/Products',
    name: 'Products',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/Catalogue/Products/Products.vue'),
  },

  {
    path: '/DigitalProducts',
    name: 'DigitalProducts',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/Catalogue/Products/DigitalProducts.vue'),
  },
  {
    path: '/DigitalProductsList',
    name: 'DigitalProductsList',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/Catalogue/Products/DigitalProductsList.vue'),
  },

  {
    path: '/DigitalPins',
    name: 'DigitalPins',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/Catalogue/Products/DigitalPins.vue'),
  },

  {
    path: '/loadDigitalPins/:id',
    name: 'loadDigitalPins',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/Catalogue/Products/LoadDigitalPins.vue'),
  },

  {
    path: '/EditDigitalPins/:id',
    name: 'EditDigitalPins',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/Catalogue/Products/EditDigitalPins.vue'),
  },

  {
    path: '/NewProduct',
    name: 'NewProduct',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/Catalogue/Products/NewProduct.vue'),
  },

  {
    path: '/CreateProduct',
    name: 'CreateProduct',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/Catalogue/Products/CreateProduct.vue'),
  },

  {
    path: '/EditProduct',
    name: 'EditProduct',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/Catalogue/Products/EditProduct.vue'),
  },

  {
    path: '/StockProducts',
    name: 'StockProducts',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/Catalogue/Products/StockProducts.vue'),
  },

  {
    path: '/EditOfferPrice',
    name: 'EditOfferPrice',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/Catalogue/Products/EditOfferPrice.vue'),
  },

  {
    path: '/EditDimensions',
    name: 'EditDimensions',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/Catalogue/Products/EditDimensions.vue'),
  },

  {
    path: '/EditCategories',
    name: 'EditCategories',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/Catalogue/Products/EditCategories.vue'),
  },

  {
    path: '/EditBrands',
    name: 'EditBrands',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/Catalogue/Products/EditBrands.vue'),
  },

  {
    path: '/EditImages/:id',
    name: 'EditImages',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/Catalogue/Products/EditImages.vue'),
  },


  {
    path: '/EditListImages',
    name: 'EditListImages',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/Catalogue/Products/EditListImages.vue'),
  },

  {
    path: '/EditVariants',
    name: 'EditVariants',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/Catalogue/Products/EditVariants.vue'),
  },

  {
    path: '/CreateVariants/:id',
    name: 'CreateVariants',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/Catalogue/Products/CreateVariants.vue'),
  },

  {
    path: '/CreateListVariants',
    name: 'CreateListVariants',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/Catalogue/Products/CreateListVariants.vue'),
  },

  {
    path: '/UpdateProduct/:id',
    name: 'UpdateProduct',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/Catalogue/Products/UpdateProduct.vue'),
  },

  {
    path: '/blankProduct/:id',
    name: 'BlankProduct',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/Catalogue/Products/blanckProducts.vue'),
  },

  {
    path: '/BulkImport',
    name: 'BulkImport',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/Catalogue/Products/BulkImport.vue'),
  },

  {
    path: '/ProductSheet/:id',
    name: 'ProductSheet',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/Catalogue/Products/ProductSheet.vue'),
  },

  {
    path: '/NewShops',
    name: 'NewShops',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/Shops/NewShops.vue'),
  },

  {
    path: '/UpdateShops/:id',
    name: 'UpdateShops',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/Shops/UpdateShops.vue'),
  },
  {
    path: '/UpdateShop',
    name: 'UpdateShop',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/Shops/UpdateShop.vue'),
  },
  {
    path: '/PQRS',
    name: 'PQRS',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/PQRS/PQRS.vue'),
  },
  {
    path: '/PQRSResponse/:id',
    name: 'PQRSResponse',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/PQRS/PQRSResponse.vue'),
  },
  {
    path: '/Wizard',
    name: 'Wizard',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/Sales/Catalogue/Products/Wizard.vue'),
  },

  {
    path: '/Clients',
    name: 'Clients',
    component: () => import(/* webpackChunkName: "index2" */ '../views/admin/testEmit.vue'),
  },

  //components
  {
    path: '/components/tabs',
    name: 'tabs',
    component: () => import(/* webpackChunkName: "components-tabs" */ '../views/components/tabs.vue'),
  },
  {
    path: '/components/accordions',
    name: 'accordions',
    component: () => import(/* webpackChunkName: "components-accordions" */ '../views/components/accordions.vue'),
  },
  {
    path: '/components/modals',
    name: 'modals',
    component: () => import(/* webpackChunkName: "components-modals" */ '../views/components/modals.vue'),
  },
  {
    path: '/components/cards',
    name: 'cards',
    component: () => import(/* webpackChunkName: "components-cards" */ '../views/components/cards.vue'),
  },
  {
    path: '/components/carousel',
    name: 'carousel',
    component: () => import(/* webpackChunkName: "components-carousel" */ '../views/components/carousel.vue'),
  },

  {
    path: '/components/timeline',
    name: 'timeline',
    component: () => import(/* webpackChunkName: "components-timeline" */ '../views/components/timeline.vue'),
  },
  {
    path: '/components/media-object',
    name: 'media-object',
    component: () => import(/* webpackChunkName: "components-media-object" */ '../views/components/media_object.vue'),
  },
  {
    path: '/components/list-group',
    name: 'list-group',
    component: () => import(/* webpackChunkName: "components-list-group" */ '../views/components/list_group.vue'),
  },
  {
    path: '/components/pricing-table',
    name: 'pricing-table',
    component: () => import(/* webpackChunkName: "components-pricing-table" */ '../views/components/pricing_table.vue'),
  },
  {
    path: '/components/notifications',
    name: 'notifications',
    component: () => import(/* webpackChunkName: "components-notifications" */ '../views/components/toast.vue'),
  },

  {
    path: '/components/lightbox',
    name: 'lightbox',
    component: () => import(/* webpackChunkName: "components-lightbox" */ '../views/components/lightbox.vue'),
  },
  {
    path: '/components/countdown',
    name: 'countdown',
    component: () => import(/* webpackChunkName: "components-countdown" */ '../views/components/countdown.vue'),
  },
  {
    path: '/components/counter',
    name: 'counter',
    component: () => import(/* webpackChunkName: "components-counter" */ '../views/components/counter.vue'),
  },
  {
    path: '/components/sweetalert',
    name: 'sweetalert',
    component: () => import(/* webpackChunkName: "components-sweetalert" */ '../views/components/sweetalert.vue'),
  },

  //fonts
  {
    path: '/font-icons',
    name: 'font-icons',
    component: () => import(/* webpackChunkName: "font-icons" */ '../views/font_icons.vue'),
  },

  //pages
  {
    path: '/pages/helpdesk',
    name: 'helpdesk',
    component: () => import(/* webpackChunkName: "pages-helpdesk" */ '../views/pages/helpdesk.vue'),
  },
  {
    path: '/pages/contact-us',
    name: 'contact-us',
    component: () => import(/* webpackChunkName: "pages-contact-us" */ '../views/pages/contact_us.vue'),
  },
  {
    path: '/pages/faq',
    name: 'faq',
    component: () => import(/* webpackChunkName: "pages-faq" */ '../views/pages/faq.vue'),
  },
  {
    path: '/pages/faq2',
    name: 'faq2',
    component: () => import(/* webpackChunkName: "pages-faq2" */ '../views/pages/faq2.vue'),
  },
  {
    path: '/pages/privacy-policy',
    name: 'privacy-policy',
    component: () => import(/* webpackChunkName: "pages-privacy-policy" */ '../views/pages/privacy_policy.vue'),
  },
  {
    path: '/pages/coming-soon',
    name: 'coming-soon',
    component: () => import(/* webpackChunkName: "pages-coming-soon" */ '../views/pages/coming_soon.vue'),
    meta: { layout: 'auth' },
  },
  {
    path: '/pages/error401',
    name: 'error401',
    component: () => import(/* webpackChunkName: "pages-error404" */ '../views/pages/error401.vue'),
    meta: { layout: 'auth' },
  },
  {
    path: '/pages/error404',
    name: 'error404',
    component: () => import(/* webpackChunkName: "pages-error404" */ '../views/pages/error404.vue'),
    meta: { layout: 'auth' },
  },

  {
    path: '/pages/error500',
    name: 'error500',
    component: () => import(/* webpackChunkName: "pages-error500" */ '../views/pages/error500.vue'),
    meta: { layout: 'auth' },
  },
  {
    path: '/pages/error503',
    name: 'error503',
    component: () => import(/* webpackChunkName: "pages-error503" */ '../views/pages/error503.vue'),
    meta: { layout: 'auth' },
  },
  {
    path: '/pages/maintenence',
    name: 'maintenence',
    component: () => import(/* webpackChunkName: "pages-maintenence" */ '../views/pages/maintenence.vue'),
    meta: { layout: 'auth' },
  },
  {
    path: '/pages/blank-page',
    name: 'blank-page',
    component: () => import(/* webpackChunkName: "pages-blank-page" */ '../views/pages/blank_page.vue'),
  },
  {
    path: '/pages/elppaymentreturn/:id',
    name: 'elppaymentreturn',
    component: () => import(/* webpackChunkName: "pages-blank-page" */ '../views/pages/elpPaymentReturn.vue'),
  },
  {
    path: '/pages/bepayReturn/:id',
    name: 'bepayReturn',
    component: () => import(/* webpackChunkName: "pages-blank-page" */ '../views/pages/bepayReturn.vue'),
  },
  {
    path: '/pages/elppaymentreturn/:id',
    name: 'elppaymentreturn',
    component: () => import(/* webpackChunkName: "pages-blank-page" */ '../views/pages/elpPaymentReturn.vue'),
  },
  {
    path: '/pages/testPDFBepay',
    name: 'testPDFBepay',
    component: () => import(/* webpackChunkName: "pages-blank-page" */ '../views/pages/testPDFBepay.vue'),
  },
  {
    path: '/pages/testPDF',
    name: 'testPDF',
    component: () => import(/* webpackChunkName: "pages-blank-page" */ '../views/pages/testPDF.vue'),
  },
  {
    path: '/ELP/Signature',
    name: 'testCanvas',
    component: () => import(/* webpackChunkName: "pages-blank-page" */ '../views/admin/Sales/PaymentMethods/modules/ELP/signature.vue'),
  },
  {
    path: '/pages/sample',
    name: 'sample',
    component: () => import(/* webpackChunkName: "pages-sample" */ '../views/pages/sample.vue'),
  },

  //auth
  {
    path: '/auth/login-boxed',
    name: 'login-boxed',
    component: () => import(/* webpackChunkName: "auth-login-boxed" */ '../views/auth/login_boxed.vue'),
    meta: { layout: 'auth' },
  },
  {
    path: '/auth/register-boxed',
    name: 'register-boxed',
    component: () => import(/* webpackChunkName: "auth-register-boxed" */ '../views/auth/register_boxed.vue'),
    meta: { layout: 'auth' },
  },
  {
    path: '/auth/lockscreen-boxed',
    name: 'lockscreen-boxed',
    component: () => import(/* webpackChunkName: "auth-lockscreen-boxed" */ '../views/auth/lockscreen_boxed.vue'),
    meta: { layout: 'auth' },
  },
  {
    path: '/auth/pass-recovery',
    name: 'pass-recovery-boxed',
    component: () => import(/* webpackChunkName: "auth-pass-recovery-boxed" */ '../views/auth/pass_recovery.vue'),
    meta: { layout: 'auth' },
  },
  {
    path: '/log_out',
    name: 'out',
    component: () => import(/* webpackChunkName: "pages-blank-page" */ '../views/logOut/lout.vue'),
  },

  {
    path: '/selectShop',
    name: 'selectShop',
    component: () => import(/* webpackChunkName: "pages-blank-page" */ '../views/auth/selectShop.vue'),
    meta: { layout: 'auth' },
  },

  {
    path: '/lamp/:id',
    name: 'lamp',
    component: () => import(/* webpackChunkName: "pages-blank-page" */ '../views/auth/lamp.vue'),
    meta: { layout: 'auth' },
  },

  {
    path: '/error401',
    name: 'err401',
    component: () => import(/* webpackChunkName: "pages-blank-page" */ '../views/pages/terror401.vue'),
  },

  {
    path: '/auth/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "auth-login" */ '../views/auth/login.vue'),
    meta: { layout: 'auth' },
  },
  {
    path: '/auth/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "auth-register" */ '../views/auth/register.vue'),
    meta: { layout: 'auth' },
  },
  {
    path: '/auth/lockscreen',
    name: 'lockscreen',
    component: () => import(/* webpackChunkName: "auth-lockscreen" */ '../views/auth/lockscreen.vue'),
    meta: { layout: 'auth' },
  },
  {
    path: '/auth/pass-recovery',
    name: 'pass-recovery',
    component: () => import(/* webpackChunkName: "auth-pass-recovery" */ '../views/auth/pass_recovery.vue'),
    meta: { layout: 'auth' },
  },

  //elements
  {
    path: '/elements/alerts',
    name: 'alerts',
    component: () => import(/* webpackChunkName: "elements-alerts" */ '../views/elements/alerts.vue'),
  },
  {
    path: '/elements/avatar',
    name: 'avatar',
    component: () => import(/* webpackChunkName: "elements-avatar" */ '../views/elements/avatar.vue'),
  },
  {
    path: '/elements/badges',
    name: 'badges',
    component: () => import(/* webpackChunkName: "elements-badges" */ '../views/elements/badges.vue'),
  },
  {
    path: '/elements/breadcrumbs',
    name: 'breadcrumbs',
    component: () => import(/* webpackChunkName: "elements-breadcrumbs" */ '../views/elements/breadcrumbs.vue'),
  },
  {
    path: '/elements/buttons',
    name: 'buttons',
    component: () => import(/* webpackChunkName: "elements-buttons" */ '../views/elements/buttons.vue'),
  },
  {
    path: '/elements/buttons-group',
    name: 'buttons-group',
    component: () => import(/* webpackChunkName: "elements-buttons-group" */ '../views/elements/buttons_group.vue'),
  },
  {
    path: '/elements/color-library',
    name: 'color-library',
    component: () => import(/* webpackChunkName: "elements-color-library" */ '../views/elements/color_library.vue'),
  },
  {
    path: '/elements/dropdown',
    name: 'dropdown',
    component: () => import(/* webpackChunkName: "elements-dropdown" */ '../views/elements/dropdown.vue'),
  },
  {
    path: '/elements/infobox',
    name: 'infobox',
    component: () => import(/* webpackChunkName: "elements-infobox" */ '../views/elements/infobox.vue'),
  },
  {
    path: '/elements/jumbotron',
    name: 'jumbotron',
    component: () => import(/* webpackChunkName: "elements-jumbotron" */ '../views/elements/jumbotron.vue'),
  },
  {
    path: '/elements/loader',
    name: 'loader',
    component: () => import(/* webpackChunkName: "elements-loader" */ '../views/elements/loader.vue'),
  },
  {
    path: '/elements/pagination',
    name: 'pagination',
    component: () => import(/* webpackChunkName: "elements-pagination" */ '../views/elements/pagination.vue'),
  },
  {
    path: '/elements/popovers',
    name: 'popovers',
    component: () => import(/* webpackChunkName: "elements-popovers" */ '../views/elements/popovers.vue'),
  },
  {
    path: '/elements/progress-bar',
    name: 'progress-bar',
    component: () => import(/* webpackChunkName: "elements-progress-bar" */ '../views/elements/progress_bar.vue'),
  },
  {
    path: '/elements/search',
    name: 'search',
    component: () => import(/* webpackChunkName: "elements-search" */ '../views/elements/search.vue'),
  },
  {
    path: '/elements/tooltips',
    name: 'tooltips',
    component: () => import(/* webpackChunkName: "elements-tooltips" */ '../views/elements/tooltips.vue'),
  },
  {
    path: '/elements/treeview',
    name: 'treeview',
    component: () => import(/* webpackChunkName: "elements-treeview" */ '../views/elements/treeview.vue'),
  },
  {
    path: '/elements/typography',
    name: 'typography',
    component: () => import(/* webpackChunkName: "elements-typography" */ '../views/elements/typography.vue'),
  },

  //tables
  {
    path: '/tables',
    name: 'tables',
    component: () => import(/* webpackChunkName: "tables" */ '../views/tables.vue'),
  },

  //users
  {
    path: '/users/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "users-profile" */ '../views/users/profile.vue'),
  },
  {
    path: '/users/account-setting',
    name: 'account-setting',
    component: () => import(/* webpackChunkName: "users-account-setting" */ '../views/users/account_setting.vue'),
  },

  //drag&drop
  {
    path: '/dragndrop',
    name: 'dragndrop',
    component: () => import(/* webpackChunkName: "dragndrop" */ '../views/dragndrop.vue'),
  },

  //charts
  {
    path: '/charts/apex-chart',
    name: 'apex-chart',
    component: () => import(/* webpackChunkName: "charts-apex-chart" */ '../views/charts/apex_chart.vue'),
  },

  //widgets
  {
    path: '/widgets',
    name: 'widgets',
    component: () => import(/* webpackChunkName: "widgets" */ '../views/widgets.vue'),
  },

  //forms
  {
    path: '/forms/basic',
    name: 'basic',
    component: () => import(/* webpackChunkName: "forms-basic" */ '../views/forms/basic.vue'),
  },
  {
    path: '/forms/input-group',
    name: 'input-group',
    component: () => import(/* webpackChunkName: "forms-input-group" */ '../views/forms/input_group.vue'),
  },
  {
    path: '/forms/layouts',
    name: 'layouts',
    component: () => import(/* webpackChunkName: "forms-layouts" */ '../views/forms/layouts.vue'),
  },
  {
    path: '/forms/validation',
    name: 'validation',
    component: () => import(/* webpackChunkName: "forms-validation" */ '../views/forms/validation.vue'),
  },
  {
    path: '/forms/checkbox-radio',
    name: 'checkbox-radio',
    component: () => import(/* webpackChunkName: "forms-checkbox-radio" */ '../views/forms/checkbox_radio.vue'),
  },
  {
    path: '/forms/switches',
    name: 'switches',
    component: () => import(/* webpackChunkName: "forms-switches" */ '../views/forms/switches.vue'),
  },
  {
    path: '/forms/wizards',
    name: 'wizards',
    component: () => import(/* webpackChunkName: "forms-wizards" */ '../views/forms/wizards.vue'),
  },
  {
    path: '/forms/file-upload',
    name: 'file-upload',
    component: () => import(/* webpackChunkName: "forms-file-upload" */ '../views/forms/fileupload.vue'),
  },
  {
    path: '/forms/clipboard',
    name: 'clipboard',
    component: () => import(/* webpackChunkName: "forms-clipboard" */ '../views/forms/clipboard.vue'),
  },
  {
    path: '/forms/date-picker',
    name: 'date-picker',
    component: () => import(/* webpackChunkName: "forms-date-picker" */ '../views/forms/date_range_picker.vue'),
  },
  {
    path: '/forms/input-mask',
    name: 'input-mask',
    component: () => import(/* webpackChunkName: "forms-input-mask" */ '../views/forms/input_mask.vue'),
  },
  {
    path: '/forms/quill-editor',
    name: 'quill-editor',
    component: () => import(/* webpackChunkName: "forms-quill-editor" */ '../views/forms/quill_editor.vue'),
  },
  {
    path: '/forms/touchspin',
    name: 'touchspin',
    component: () => import(/* webpackChunkName: "forms-touchspin" */ '../views/forms/touchspin.vue'),
  },
  {
    path: '/forms/markdown-editor',
    name: 'markdown-editor',
    component: () => import(/* webpackChunkName: "forms-markdown-editor" */ '../views/forms/markdown_editor.vue'),
  },
  {
    path: '/forms/select2',
    name: 'select2',
    component: () => import(/* webpackChunkName: "forms-select2" */ '../views/forms/select2.vue'),
  },

  //apps
  {
    path: '/apps/chat',
    name: 'chat',
    component: () => import(/* webpackChunkName: "apps-chat" */ '../views/apps/chat.vue'),
  },
  {
    path: '/apps/mailbox',
    name: 'mailbox',
    component: () => import(/* webpackChunkName: "apps-mailbox" */ '../views/apps/mailbox.vue'),
  },
  {
    path: '/apps/todo-list',
    name: 'todo-list',
    component: () => import(/* webpackChunkName: "apps-todo-list" */ '../views/apps/todo_list.vue'),
  },
  {
    path: '/apps/contacts',
    name: 'contacts',
    component: () => import(/* webpackChunkName: "apps-contacts" */ '../views/apps/contacts.vue'),
  },
  {
    path: '/apps/notes',
    name: 'notes',
    component: () => import(/* webpackChunkName: "apps-notes" */ '../views/apps/notes.vue'),
  },
  {
    path: '/apps/scrumboard',
    name: 'scrumboard',
    component: () => import(/* webpackChunkName: "apps-scrumboard" */ '../views/apps/scrumboard.vue'),
  },
  {
    path: '/apps/calendar',
    name: 'calendar',
    component: () => import(/* webpackChunkName: "apps-calendar" */ '../views/apps/calendar.vue'),
  },
  {
    path: '/apps/invoice/list',
    name: 'invoice-list',
    component: () => import(/* webpackChunkName: "apps-invoice-list" */ '../views/apps/invoice/list.vue'),
  },
  {
    path: '/apps/invoice/preview',
    name: 'invoice-preview',
    component: () => import(/* webpackChunkName: "apps-invoice-preview" */ '../views/apps/invoice/preview.vue'),
  },
  {
    path: '/apps/invoice/add',
    name: 'invoice-add',
    component: () => import(/* webpackChunkName: "apps-invoice-add" */ '../views/apps/invoice/add.vue'),
  },
  {
    path: '/apps/invoice/edit',
    name: 'invoice-edit',
    component: () => import(/* webpackChunkName: "apps-invoice-edit" */ '../views/apps/invoice/edit.vue'),
  },

  //tables
  {
    path: '/tables/basic',
    name: 'table-basic',
    component: () => import(/* webpackChunkName: "tables-basic" */ '../views/tables/basic.vue'),
  },
  {
    path: '/tables/striped',
    name: 'striped',
    component: () => import(/* webpackChunkName: "tables-striped" */ '../views/tables/striped.vue'),
  },
  {
    path: '/tables/order-sorting',
    name: 'order-sorting',
    component: () => import(/* webpackChunkName: "tables-order-sorting" */ '../views/tables/order_sorting.vue'),
  },
  {
    path: '/tables/multi-column',
    name: 'multi-column',
    component: () => import(/* webpackChunkName: "tables-multi-column" */ '../views/tables/multi_column.vue'),
  },
  {
    path: '/tables/multiple-tables',
    name: 'multiple-tables',
    component: () => import(/* webpackChunkName: "tables-multiple-tables" */ '../views/tables/multiple_tables.vue'),
  },
  {
    path: '/tables/alt-pagination',
    name: 'alt-pagination',
    component: () => import(/* webpackChunkName: "tables-alt-pagination" */ '../views/tables/alt_pagination.vue'),
  },
  {
    path: '/tables/custom',
    name: 'custom',
    component: () => import(/* webpackChunkName: "tables-custom" */ '../views/tables/custom.vue'),
  },
  {
    path: '/tables/range-search',
    name: 'range-search',
    component: () => import(/* webpackChunkName: "tables-range-search" */ '../views/tables/range_search.vue'),
  },
  {
    path: '/tables/export',
    name: 'export',
    component: () => import(/* webpackChunkName: "tables-export" */ '../views/tables/export.vue'),
  },
  {
    path: '/tables/live-dom-ordering',
    name: 'live-dom-ordering',
    component: () => import(/* webpackChunkName: "tables-live-dom-ordering" */ '../views/tables/live_dom_ordering.vue'),
  },
  {
    path: '/tables/miscellaneous',
    name: 'miscellaneous',
    component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/tables/miscellaneous.vue'),
  },
];

const router = new createRouter({
  // mode: 'history',
  history: createWebHistory(),
  linkExactActiveClass: 'active',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.layout && to.meta.layout == 'auth') {
    store.commit('setLayout', 'auth');
  } else {
    store.commit('setLayout', 'app');
  }
  next(true);
});

export default router;
